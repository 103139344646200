<template>
  <div class="q-pa-sm">
    <q-card>
      <div class="row items-center">
        <div class="q-pl-sm" style="min-width: 30px;">
          <search
            dense
            is-expandable
            @submit="handleSearch"
          />
        </div>
        <q-tabs
          v-model="tab"
          active-color="primary"
          indicator-color="primary"
          narrow-indicator
          inline-label
          align="justify"
          class="tab-list text-grey"
        >
          <q-tab
            name="all"
            :label="$t('APPs')"
            no-caps
          />

          <q-tab
            v-for="category in categories"
            :key="category.id"
            :name="category.id"
            :label="$t(category.name)"
            class="text-capitalize"
            no-caps
          />
        </q-tabs>

        <q-space />

        <div class="q-px-md">
          <q-btn
            color="dark"
            text-color="white"
            class="fit"
            :label="$t('Filter')"
            no-caps
            unelevated
            @click="toggleFilters"
          />
        </div>
      </div>

      <q-separator />

      <filter-collapse
        :is-open="isOpen"
        :options="{
          defaultFilter: filter,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="toggleFilters"
      />

      <q-card-section>
        <div
          v-if="apps.length <= 0 && !isLoading"
          class="row items-center justify-center text-center text-subtitle1"
          style="height: calc(100vh - 130px);"
        >
          {{ $t('No founded apps') }}
        </div>

        <div class="row">
          <div
            v-for="item in apps"
            :key="item.id"
            class="col-12 col-sm-6 col-md-4 q-pa-sm"
          >
            <div class="row clickable">
              <div class="col-5 text-center" @click="handleApp(item)">
                <q-card class="q-pa-sm" style="height: 150px;">
                  <img
                    v-if="item.image"
                    style="height: 100%; width: 100%; object-fit: contain;"
                    :src="item.image"
                    :alt="item.name"
                  >

                  <div
                    v-else
                    class="row justify-center items-center full-height text-center text-h6"
                  >
                    {{ $t('No image') }}
                  </div>
                </q-card>

                <div v-if="item._embedded && item._embedded.category" class="text-caption q-mt-xs">
                  {{ $t(item._embedded.category.name) }}
                </div>
              </div>

              <div class="col-7 q-pa-sm">
                <h6 class="q-ma-none">
                  {{ item.name }}

                  <q-btn
                    v-if="isAdministrator"
                    color="transparent"
                    text-color="dark"
                    flat
                    size="sm"
                    icon="edit"
                    @click="handleOpen(item)"
                  />
                </h6>

                <small v-if="item.developer" class="text-subtitle1 text-grey-7">
                  {{ $t('By') + ' ' + item.developer }}
                </small>

                <p v-if="item.description" class="q-ma-none text-subtitle2 text-grey" @click="handleApp(item)">
                  {{ $t(item.description) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="isEnabledLoading"
          v-observe-visibility="loadNextApps({ page: appsPage + 1, search, per_page: 25 })"
        />

        <div
          v-if="isLoading"
          class="text-primary col-12 row fit justify-center items-center"
          style="min-height: calc(100vh - 130px);"
        >
          <q-spinner
            color="light-blue-9"
            size="3rem"
          />
        </div>
      </q-card-section>
    </q-card>

    <app-modal ref="appModal" @submit="upsertApp" />

    <integration-modal ref="integrationModal" />
  </div>
</template>

<script>
// Directives
import { ObserveVisibility } from 'vue-observe-visibility'

// Components
import IntegrationModal from '../components/modals/IntegrationModal.vue'
import Search from '../components/search/Search.vue'
import FilterCollapse from '../components/filters/FilterCollapse.vue'
import AppModal from '../components/modals/AppModal.vue'

// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Apps',
  components: {
    IntegrationModal,
    Search,
    FilterCollapse,
    AppModal
  },
  directives: {
    ObserveVisibility
  },
  data () {
    return {
      categories: [],
      apps: [],
      appsPage: 0,
      appsTotalPages: 1,
      isLoading: false,
      search: '',
      tab: 'all',
      isOpen: false,
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'state',
        'id',
        'name'
      ],
      filter: [{ type: 'eq', field: 'state', value: 'active' }]
    }
  },
  computed: {
    ...mapGetters([
      'isAdministrator'
    ]),
    isEnabledLoading () {
      return this.appsPage < this.appsTotalPages & !this.isLoading
    }
  },
  watch: {
    tab () {
      this.filter = [{ type: 'eq', field: 'state', value: 'active' }]
      this.loadNextApps({ page: 1, search: this.search })
    }
  },
  mounted () {
    this.loadCategories()
  },
  methods: {
    ...mapMutations([
      'setApp'
    ]),
    loadCategories () {
      const query = {
        page: 1,
        per_page: 50,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      return this.$service.integrationCategory.getAll(query)
        .then(data => {
          this.categories = data.items
          return data
        })
    },
    handleOpen (app) {
      this.$refs.appModal.open(app)
    },
    upsertApp (app) {
      let isExist = false

      this.apps = this.apps.map(x => {
        if (x.id == app.id) {
          isExist = true
          return app
        }

        return x
      })

      if (!isExist) {
        this.apps = [app, ...this.apps]
      }

      return this.apps
    },
    handleFiltersSubmit (filter) {
      this.filter = filter

      if (!this.filter.find(x => x.field === 'state')) {
        this.filter.push({ type: 'eq', field: 'state', value: 'active' })
      }

      this.isOpen = false

      return this.loadNextApps({ per_page: 25, page: 1 })
    },
    toggleFilters () {
      this.isOpen = !this.isOpen
    },
    handleSearch (search) {
      this.search = search
      return this.loadNextApps({ search, page: 1 })
    },
    handleApp (app) {
      this.setApp(app)
      this.$router.push(`/apps/apps/entity/${app.id}`)
      // this.$refs.integrationModal.open(app)
    },
    getTypeUser () {
      const roles = this.getUserRoles()
      if (roles.includes(3)) {
        return 'admin'
      }else if (roles.includes(29)) {
        return 'supervisior'
      }else if (roles.includes(7)) {
        return 'employee'
      }else if (roles.includes(8)) {
        return 'client'
      }
    },
    getUserRoles () {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const roles = userData.roles
      const rolesId = roles.map(role =>{
        return role.id
      })
      return rolesId
    },
    loadNextApps (params = {}) {
      const query = {
        per_page: 25,
        page: 1,
        filter: [],
        ...params
      }

      query.filter = this.filter

      // if(this.getTypeUser() === 'client'){
      //   query.filter.push({type: "in", field: "id", values: [259, 260]})
      // }

      if (this.tab !== 'all') {
        query.filter.push({ type: 'eq', field: 'category', value: this.tab })
      }

      this.isLoading = true

      return this.$service.iIntegrationService.getAll(query)
        .then(({ items, page, totalPages }) => {
          this.appsPage = page
          this.appsTotalPages = totalPages

          if (page <= 1) {
            this.apps = items
          } else {
            this.apps = [
              ...this.apps,
              ...items
            ]
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
