<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('App') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section>
        <form-builder :schema="schema" />
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Utils
import _ from 'lodash'
import { convertEmbedded, difference } from '../../helpers/request-helpers'

export default {
  name: 'AppModal',
  emits: ['submit'],
  data () {
    return {
      isOpen: false,
      model: null,
      cleanModel: null
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                required: true,
                value: this.model.name,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (name) => {
                  this.model.name = name
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Category'),
                field: 'category',
                value: this.model.category,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'eq', value: 'active' }
                    ]
                  }

                  return this.$service.integrationCategory.getAll(query)
                },
                onChange: (category) => {
                  this.model.category = category
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Developer'),
                field: 'developer',
                value: this.model.developer,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (developer) => {
                  this.model.developer = developer
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Description'),
                field: 'description',
                required: true,
                value: this.model.description,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (description) => {
                  this.model.description = description
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Image'),
                field: 'image',
                required: true,
                value: this.model.image,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (image) => {
                  this.model.image = image
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    close () {
      this.isOpen = false
    },
    open (model) {
      this.model = _.cloneDeep(model)
      this.cleanModel = _.cloneDeep(model)
      this.isOpen = true
    },
    save () {
      let data = this.model.id
        ? convertEmbedded(difference({ ...this.model, category: this.model.category && this.model.category.id }, this.cleanModel), [])
        : convertEmbedded(this.model)

      if (Object.keys(data).length === 0) {
        return Promise.resolve([])
      }

      return this.$service.iIntegrationService.save(data, this.model.id)
        .then(item => {
          this.close()
          this.$emit('submit', item)
        })
    }
  }
}
</script>
