<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 44vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ title }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <div v-if="subtitle && !isLoading" class="text-subtitle1 text-center">
        {{ subtitle }}
      </div>

      <div v-if="model && model.id" class="text-center">
        <div class="text-caption q-my-sm">
          {{ $t('Click here to create new one') }}
        </div>

        <q-btn
          color="light-blue-9"
          text-color="white"
          icon="add"
          size="sm"
          no-caps
          unelevated
          @click="handleAddNew"
        />
      </div>

      <div
        v-if="isLoading"
        class="row fit justify-center items-center"
        style="min-height: 260px;"
      >
        <q-spinner
          color="light-blue-9"
          size="3rem"
        />
      </div>

      <q-card-section v-else class="q-pt-none">
        <integration-form
          :integration="app"
          :model="model"
          :is-d-s="isDS"
          @change="handleChange"
        />
      </q-card-section>

      <q-card-actions v-if="!isLoading" class="justify-center">
        <q-btn
          color="light-blue-9"
          :label="btnLabel"
          @click="save"
        />
      </q-card-actions>
    </q-card>

    <integrations-modal ref="integrationsModal" />
  </q-dialog>
</template>

<script>
// Utils
import _ from 'lodash'
import { convertEmbedded, difference } from '../../helpers/request-helpers'
import { convertSourceIntegration } from '../../helpers/helpers'

// Components
import IntegrationForm from '../integration-wizard/IntegrationForm.vue'
import IntegrationsModal from './IntegrationsModal.vue'

// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'IntegrationModal',
  components: {
    IntegrationForm,
    IntegrationsModal
  },
  data () {
    return {
      isOpen: false,
      isDS: false,
      isLoading: false,
      app: null,
      model: {},
      cleanModel: {}
    }
  },
  computed: {
    btnLabel () {
      return this.model.id
        ? this.$t('Save')
        : this.$t('Install')
    },
    subtitle () {
      return this.model.id
        ? this.$t('You already has integration with this app.')
        : ''
    },
    title () {
      if (!this.app) {
        return 'App'
      }

      return this.app.name
    }
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    handleChange (update) {
      this.model = update
    },
    save () {
      this.isLoading = true

      return Promise.resolve(this.isDS)
        .then(isDS => {
          return isDS
            ? this.saveDSIntegration()
            : this.saveSIntegration()
        })
        .then(data => {
          if (Object.keys(data).length > 0) {
            this.close()
          } else {
            this.addWarningNotification('No changes!')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    saveDSIntegration () {
      const ignoredFields = ['deliveryService', 'sender']
      let data = this.model.id
        ? convertEmbedded(difference(this.model, this.cleanModel), ignoredFields)
        : convertEmbedded(this.model)

      if (Object.keys(data).length === 0) {
        return Promise.resolve({})
      }

      if (data.settings) {
        data = convertSourceIntegration(data)
      }

      return this.$service.deliveryServiceIntegration.save(data, this.model.id)
    },
    saveSIntegration () {
      if (this.model.id) {
        let data = convertEmbedded(difference(this.model, this.cleanModel))

        if (data.settings) {
          data = convertSourceIntegration(data)
        }

        if (Object.keys(data).length === 0) {
          return Promise.resolve({})
        }

        return this.$service.iSource.save(data, this.model.id)
      }

      let data = { ...this.model }
      data = convertSourceIntegration(data)
      return this.$service.iSource.install(this.app.id, data)
    },
    mutateApp () {
      const mutations = {
        shopify: () => {
          this.app = {
            ...this.app,
            settings: {
              main: [
                {
                  name: 'url',
                  type: 'Laminas\\Form\\Element\\Url',
                  label: 'Store',
                  required: true,
                  styleClasses: 'col-12 q-px-xs'
                }
              ]
            }
          }
        }
      }

      if (mutations[this.app.extId]) {
        mutations[this.app.extId]()
      }

      return this.app
    },
    open (app) {
      this.app = app
      this.mutateApp()
      this.isOpen = true

      this.isLoading = true
      return Promise.resolve(this.app.adapter.includes('DeliveryServices'))
        .then(isDS => {
          this.isDS = isDS

          return isDS
            ? this.getDSIntegration()
            : this.getSIntegration()
        })
        .then(({ items, totalItems }) => {
          if (totalItems === 1) {
            return items[0]
          }

          if (totalItems > 1) {
            return this.$refs.integrationsModal.show(items)
          }

          return null
        })
        .then(item => {
          if (item) {
            this.model = item
            this.cleanModel = _.cloneDeep(item)
          } else {
            this.handleAddNew()
          }

          return this.model
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleAddNew () {
      return this.isDS
        ? this.setNewDSIntegration()
        : this.setNewSIntegration()
    },
    setNewSIntegration () {
      this.model = {
        name: '',
        settings: {},
        _embedded: {
          owner: null
        }
      }
    },
    setNewDSIntegration () {
      this.model = {
        name: null,
        comment: null,
        settings: {},
        _embedded: {
          deliveryService: this.app,
          sender: null
        }
      }
    },
    getSIntegration () {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'handler', value: this.app.extId }
        ]
      }

      return this.$service.iSource.getAll(query)
    },
    getDSIntegration () {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'extId', value: this.app.extId }
        ]
      }

      return this.$service.deliveryService.getAll(query)
        .then(data => {
          if (data.totalItems <= 0) {
            return data
          }

          this.app = {
            ...data.items[0],
            settings: this.app.settings
          }

          const query = {
            per_page: 25,
            page: 1,
            filter: [
              { type: 'eq', field: 'deliveryService', value: data.items[0].id }
            ]
          }

          return this.$service.deliveryServiceIntegration.getAll(query)
        })
    },
    close () {
      this.model = {}
      this.cleanModel = {}
      this.isOpen = false
    }
  }
}
</script>
